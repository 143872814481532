import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceCategories as serviceCategoriesResource, serviceGroups as serviceGroupResource, services as servicesResource, staffCategories, staffs as staffsResource } from '~/api/Resources';
import { TEN_MINS_IN_MILLISECONDS } from '~/api/models/Constants';
import { fetchResource } from '~/common/network/network';
import { selectBranchId } from '~/common/slices/app.slice';
import { selectCartItems } from '~/common/slices/cart.slice';
import { selectClients } from '~/common/slices/client.slice';
import { setServiceCategories } from '~/common/slices/model.slice';
import { buildViewModel as _buildViewModel, finishOnlyCategories } from './ServicesViewModel';
export var useServices = function useServices(options) {
  var cartItems = useSelector(selectCartItems);
  var clients = useSelector(selectClients);
  var branchId = useSelector(selectBranchId);
  var dispatch = useDispatch();
  var _useQuery = useQuery([serviceGroupResource.path, branchId], function () {
      return fetchResource(serviceGroupResource);
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS
    }),
    serviceGroups = _useQuery.data,
    isServiceGroupsError = _useQuery.isError,
    isServiceGroupsLoading = _useQuery.isLoading,
    refetchServiceGroups = _useQuery.refetch;
  var _useQuery2 = useQuery([staffsResource.path, branchId], function () {
      return fetchResource(staffsResource, {
        order: '+internet_pos'
      });
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS
    }),
    staffs = _useQuery2.data,
    isStaffsError = _useQuery2.isError,
    isStaffsLoading = _useQuery2.isLoading,
    refetchStaffs = _useQuery2.refetch;
  var preferredStaff = staffs === null || staffs === void 0 ? void 0 : staffs.staffs.find(function (staff) {
    return staff.id === (options === null || options === void 0 ? void 0 : options.preferredStaffId);
  });
  var _useQuery3 = useQuery([servicesResource.path, branchId], function () {
      return fetchResource(servicesResource);
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS
    }),
    services = _useQuery3.data,
    isServicesError = _useQuery3.isError,
    isServicesLoading = _useQuery3.isLoading,
    refetchServices = _useQuery3.refetch;
  var _useQuery4 = useQuery([serviceCategoriesResource.path, branchId], function () {
      return fetchResource(serviceCategoriesResource);
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS
    }),
    serviceCategories = _useQuery4.data,
    isServiceCategoriesError = _useQuery4.isError,
    isServiceCategoriesLoading = _useQuery4.isLoading,
    refetchServiceCategories = _useQuery4.refetch;
  useEffect(function () {
    if (serviceCategories) {
      dispatch(setServiceCategories(serviceCategories));
    }
  }, [serviceCategories]);
  function retry() {
    if (isServiceGroupsError) {
      refetchServiceGroups();
    }
    if (isStaffsError) {
      refetchStaffs();
    }
    if (isServicesError) {
      refetchServices();
    }
    if (isServiceCategoriesError) {
      refetchServiceCategories();
    }
  }

  // Decorate the service groups with the services
  var decoratedServiceGroups = useMemo(function () {
    if (!serviceGroups || !services) return undefined;
    return {
      serviceGroups: serviceGroups.serviceGroups.map(function (serviceGroup) {
        return _objectSpread(_objectSpread({}, serviceGroup), {}, {
          items: serviceGroup.items.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              options: item.options.map(function (option) {
                var matchedService = services.services.find(function (service) {
                  return service.id === option.service.id;
                });
                return _objectSpread(_objectSpread({}, option), {}, {
                  service: matchedService || option.service
                });
              })
            });
          })
        });
      })
    };
  }, [serviceGroups, services]);
  var viewModel = useMemo(function () {
    if (options !== null && options !== void 0 && options.skipViewModel) {
      return undefined;
    }
    if (services && staffs && staffCategories && serviceCategories && decoratedServiceGroups) {
      var _cartItems = _toConsumableArray(cartItems);
      if (options !== null && options !== void 0 && options.includeCartItems) {
        _cartItems.push.apply(_cartItems, _toConsumableArray(options.includeCartItems));
      }
      var _viewModel = _buildViewModel({
        services: services.services,
        staffs: staffs.staffs,
        categories: serviceCategories.serviceCategories,
        serviceGroups: decoratedServiceGroups.serviceGroups,
        cartItems: _cartItems,
        preferredStaff: preferredStaff,
        showSpecialOffers: options === null || options === void 0 ? void 0 : options.showSpecialOffers,
        filter: options === null || options === void 0 ? void 0 : options.filter,
        clients: clients
      });
      if (options !== null && options !== void 0 && options.finishOnly) {
        return finishOnlyCategories(_viewModel);
      }
      return _viewModel;
    }
    return undefined;
  }, [services, staffs, serviceCategories, decoratedServiceGroups, options, cartItems]);
  return {
    serviceGroups: decoratedServiceGroups,
    staffs: staffs,
    services: services,
    serviceCategories: serviceCategories,
    isError: isServiceGroupsError || isStaffsError || isServicesError || isServiceCategoriesError,
    isLoading: isServiceGroupsLoading || isStaffsLoading || isServicesLoading || isServiceCategoriesLoading,
    retry: retry,
    viewModel: viewModel,
    preferredStaff: preferredStaff
  };
};