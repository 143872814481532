import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { useMemo } from 'react';
import { Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { isWeb } from '~/common/Env';
import Localize from '~/common/Localize';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import { selectFeatureFlag, FeatureFlagsEnum } from '~/common/slices/model.slice';
import CheckBox from '~/components/CheckBox';
import ListItem from '~/components/ListItem';
import UserSelectionDetail from '~/components/UserSelectionDetail';
import { Body3, Container, themed } from '~/styles/Theme';
import { getServiceGroupSelectedOptions } from '../serviceGroups/ServiceGroupViewModel';
var ServiceItem = function ServiceItem(props) {
  var service = props.service,
    onPress = props.onPress,
    onDescriptionPress = props.onDescriptionPress,
    testID = props.testID;
  var serviceDurationFeatureFlag = useSelector(selectFeatureFlag(FeatureFlagsEnum.ServiceDuration));
  var duration = useMemo(function () {
    return serviceDurationFeatureFlag && service.duration > 0 ? __jsx(Body3, null, "".concat(Localize('services.item.approx.time'), " ").concat(service.duration).concat(Localize('services.item.approx.mins'), " ")) : undefined;
  }, [service]);
  var accessory = useMemo(function () {
    if (service.selected) {
      return __jsx(CheckBox, {
        checked: true,
        style: {
          flex: 0.4
        },
        testID: testID
      });
    }
    return service.requiresUserSelection ? __jsx(Icon, {
      name: "chevron-right",
      color: "#bec5d2",
      size: 18,
      style: {
        marginLeft: 5
      }
    }) : __jsx(CheckBox, {
      checked: false,
      style: {
        flex: 0.4
      },
      testID: testID
    });
  }, [service, testID]);
  var heading = useMemo(function () {
    return service.numberOfClients && service.numberOfClients > 1 ? __jsx(React.Fragment, null, __jsx(Icon, {
      name: "user-plus",
      color: "#000",
      size: 12,
      style: {
        paddingRight: 5
      }
    }), service.numberOfClients, " ", Localize('services.item.people')) : undefined;
  }, [service.numberOfClients]);
  function _onPress() {
    if (onPress) {
      onPress(!service.selected, service);
    }
  }
  return __jsx(ServiceItemContainer, {
    keyString: "service_container_".concat(service.id)
  }, __jsx(ListItem, {
    selected: service.selected,
    onPress: _onPress,
    title: props.service.name || '',
    heading: heading,
    subtitle: duration,
    detailPrefix: props.service.hasMorePrices || props.service.pricedFromDisclaimer ? Localize('services.from') : '',
    detail: service.fromPrice,
    customAccessory: accessory,
    showChevron: service.requiresUserSelection,
    footer: __jsx(ServiceItemFooter, {
      service: service,
      onDescriptionPress: onDescriptionPress
    }),
    testID: "serviceItem",
    style: {
      flex: 1
    }
  }));
};
export default ServiceItem;
var ServiceItemFooter = function ServiceItemFooter(props) {
  var service = props.service,
    onDescriptionPress = props.onDescriptionPress;
  var serviceGroupOptionItems = useMemo(function () {
    var selectedOptions = getServiceGroupSelectedOptions(service);
    if (selectedOptions.length > 0) {
      return selectedOptions.map(function (option) {
        return {
          key: "option_".concat(service.id, "_").concat(option.id),
          title: option.name
        };
      });
    }
    return [];
  }, [service]);
  function _onDesciptionPress() {
    if (onDescriptionPress) {
      onDescriptionPress(service.name, service.description);
    }
  }
  return __jsx(View, null, service.description ? __jsx(View, {
    style: {
      flexDirection: 'row'
    }
  }, onDescriptionPress ? __jsx(Pressable, {
    onPress: _onDesciptionPress,
    testID: "serviceDescriptionLink"
  }, __jsx(View, {
    style: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  }, __jsx(Icon, {
    name: "alert-circle",
    color: "#1089CA",
    size: 16
  }), __jsx(InfoText, null, " ", Localize('services.seeInfo')))) : null) : null, serviceGroupOptionItems.length > 0 ? __jsx(UserSelectionDetail, {
    title: Localize('serviceGroup.included'),
    items: serviceGroupOptionItems
  }) : null);
};
var ServiceItemContainer = function ServiceItemContainer(props) {
  var children = props.children,
    keyString = props.keyString;
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  return __jsx(ItemContainer, {
    key: keyString,
    isMobile: isMobile
  }, children);
};
var ItemContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: ", "\n  ", "\n"])), function (props) {
  return props.isMobile ? '100%' : '50%';
}, function () {
  return isWeb() ? 'padding: 6px;' : '';
});
var InfoText = themed(Body3)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: #1089ca;\n"])));