import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { Body3, Container, themed } from '~/styles/Theme';
var UserSelectionDetail = function UserSelectionDetail(props) {
  var itemElements = props.items.map(function (item) {
    var flex = item.description != null ? {
      title: 0.6,
      description: 0.4
    } : {
      title: 1,
      description: 0
    };
    return __jsx(View, {
      style: {
        flexDirection: 'row'
      },
      key: item.key
    }, __jsx(View, {
      style: {
        flex: flex.title,
        flexDirection: 'row'
      }
    }, __jsx(Icon, {
      name: "check-circle",
      color: "#65c87a",
      size: 15,
      style: {
        paddingRight: 5
      }
    }), __jsx(ItemText, null, item.title)), __jsx(View, {
      style: {
        flex: flex.description,
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }
    }, __jsx(ItemText, null, item.description)));
  });
  var header = __jsx(View, {
    style: {
      flexDirection: 'row'
    }
  }, __jsx(View, {
    style: {
      flex: 0.35,
      paddingTop: 7
    }
  }, __jsx(BorderLine, null)), __jsx(View, {
    style: {
      flex: 0.3,
      alignItems: 'center'
    }
  }, __jsx(TitleText, null, " ", props.title, " ")), __jsx(View, {
    style: {
      flex: 0.35,
      paddingTop: 7
    }
  }, __jsx(BorderLine, null)));
  return __jsx(View, null, header, itemElements);
};
var TitleText = themed(Body3)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: #65c87a\n  padding-top: 3px\n  text-align: center;\n\n"])));
var BorderLine = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border-bottom-color: #65c87a\n  border-bottom-width: 1px\n  width: 100%\n"])));
var ItemText = themed(Body3)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: #3f4b62;\n"])));
export default UserSelectionDetail;