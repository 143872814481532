import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { SERVICE_GROUP_DIVIDER } from '~/api/models/Constants';
import Localize from '~/common/Localize';
export function buildSelectionIds(serviceGroup, selectedOptions) {
  return selectedOptions.map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      _ = _ref2[0],
      optionId = _ref2[1];
    return "".concat(serviceGroup.id).concat(SERVICE_GROUP_DIVIDER).concat(optionId);
  });
}
export function numberOfOptionsLeftToSelect(serviceGroup, selectedOptions) {
  if (!serviceGroup || !selectedOptions) {
    return 0;
  }
  return serviceGroup.items ? serviceGroup.items.length - selectedOptions.length : 0;
}
export function isOptionSelected(item, option, selectedOptions) {
  return selectedOptions.filter(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      itemId = _ref4[0],
      optionId = _ref4[1];
    return itemId === item.id && optionId === option.id;
  }).length > 0;
}
export function getServiceGroupSelectionTitle(items, item) {
  if (item.serviceOptions.length === 1) {
    var _title = Number(item.id) < 5 ? "categories.options.included.".concat(item.id) : 'categories.options.included.n';
    return Localize(_title);
  }
  if (items.length === 1) {
    return Localize('categories.options.services.n');
  }
  var title = Number(item.id) < 5 ? "categories.options.services.".concat(item.id) : 'categories.options.services.n';
  return Localize(title);
}
export function getServiceGroupIncludedOptions(viewModel) {
  var _viewModel$items;
  var included = new Map();
  (_viewModel$items = viewModel.items) === null || _viewModel$items === void 0 || _viewModel$items.forEach(function (item) {
    // no option for the user
    if (item.serviceOptions.length === 1) {
      included.set(item.id, item.serviceOptions[0]);
    }
  });
  return included;
}
export function getServiceGroupSelectedOptions(viewModel) {
  if (!viewModel.items) {
    return [];
  }
  return viewModel.items.map(function (item) {
    return item.serviceOptions.find(function (option) {
      return option.selected;
    });
  }).filter(function (item) {
    return item;
  });
}